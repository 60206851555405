<template>
  <div>
    <b-sidebar
      :sidebar-class="`${changed_sidebar ? 'sidebar-animation' : ''}`"
      bg-variant="white"
      class="sidebar-lists"
      shadow
      backdrop
      no-header
      right
      v-model="show"
      @change="changedSidebar"
    >
        <canvas-color 
          :image="cover_file" 
          v-if="cover_file !== null" 
          :key="canvas_updated"
          @color_palette="getColorPalette"
        ></canvas-color>
        <div class="d-flex py-1">
          <feather-icon
            class="close-select-campaign-modal ml-1 d-block"
            icon="XIcon"
            size="20"
            @click="show = false"
          />
        </div>
          <h2 class="ml-1">
            {{!is_adding ? $t('lists.editList') : $t('lists.createlist')}}
          </h2>

        <validation-observer
          #default="{ invalid }"
          ref="createForm"
        >
          <div class="p-2">
            <validation-provider rules="required">
              <b-form-group>
                <label for="">{{ $t('lists.tittle') }}</label>
                <b-form-input
                  v-model="list.title"
                  autofocus
                  trim
                 :placeholder="$t('lists.listTittle')"
                />
              </b-form-group>
            </validation-provider>

            <b-form-group>
              <label for="">{{ $t('campaigns.Description') }}</label>
              <b-form-textarea
                v-model="list.description"
                trim
                rows="4"
                :placeholder="$t('lists.description_list')"                
              />
            </b-form-group>
            
            <validation-provider :rules="!is_adding ? '' : 'required'">

              <b-form-group>
                <label>{{ $t('lists.image') }}</label>
                <b-form-file
                    v-model="cover_file"
                    :placeholder="$t('lists.selectImage')"
                    accept=".jpeg, .jpg, .png"
                    no-drop
                    ref="input-file"
                    @change="hasChangeFile"
                    :class="!is_adding ? 'd-none' : 'd-block'"
                    :browse-text="$t('uploadFile')"
                    />
              </b-form-group>
            </validation-provider>

            <b-avatar 
              rounded 
              :src="avatar" 
              class="avatar-edit-list" 
              button
              @click="showInputFile"
            >
            </b-avatar>
            <div class="d-flex justify-content-center mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                :disabled="invalid"
                type="submit"
                @click.once="handleAction"
              >
                <span>{{!is_adding ? $t('lists.update') : $t('lists.create')}}</span>
              </b-button>
            </div>
          </div>
        </validation-observer>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, 
  BFormGroup, 
  BFormInput, 
  BButton, 
  BFormFile, 
  BAvatar,
  BFormTextarea,
} from 'bootstrap-vue';
import service from '@/services/lists';
import utils from '@/libs/utils';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from '@validations';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CanvasColor from './CanvasColor.vue';

export default {
  name: 'SideAddList',
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BFormFile,
    BAvatar,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    CanvasColor,
  },
  props: {
    show_modal: {
      type: Boolean,
      required: true
    },
    is_adding: {
      type: Boolean,
      default: true
    },
    list_dad: {
      type: Object,
      default: () => {
        return {
          name: '',
          cover: null
        }
      }
    }
  },
  data () {
    return {
      required,
      utils,
      cover_file: null,
      avatar: null,
      show: this.show_modal,
      list: {...this.list_dad},
      changed_sidebar: true,
      canvas_updated: false,
      color_palette: [],
      interval_loading: null
    }
  },
  created() {
    if (this.list.uuid) {
      this.avatar = utils.getAssetUrl(this.list.cover);
    }
  },
  methods: {
    getColorPalette(data) {
      this.color_palette = data;
    },
    changedSidebar(val) {
      this.changed_sidebar = val;
    },
    handleAction() {
      if (this.list.uuid) {
        this.editList();
      } else {
        this.addList();
      }
    },
    addList() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      const data = new FormData();
      data.append('title', this.list.title);
      data.append('cover', this.cover_file);
      data.append('description', this.list.description !== undefined ? this.list.description : '');
      data.append('order', 0);
      data.append('color_palette', JSON.stringify(this.color_palette));
      service.createList(data).then((response) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t('lists.listcreate'),
            icon: "CheckSquareIcon",
            variant: "success",
          }
        });
        clearInterval(this.interval_loading)
        this.$vs.loading.close();
        this.show = false;
        this.$emit('update_add_list', response, this.is_adding);
      });
    },
    editList() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      const data = new FormData();
      data.append('title', this.list.title)
      data.append('description', this.list.description);
      if (this.color_palette.length > 0) data.append('color_palette', JSON.stringify(this.color_palette));
      if (this.cover_file !== null) data.append('cover', this.cover_file);

      service.updateList(data, this.list.uuid).then((response) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t('lists.list_updated'),
            icon: "FileTextIcon",
            variant: "success",
          }
        });
        clearInterval(this.interval_loading)
        this.$vs.loading.close();
        this.show = false;
        this.$emit('update_add_list', response, this.is_adding);
      });
    },
    hasChangeFile() {
      setTimeout(() => {
        this.avatar = URL.createObjectURL(this.cover_file);
        this.canvas_updated = !this.canvas_updated;
      },  200)
    },
    showInputFile() {
      this.$refs['input-file'].$refs['input'].click();
    },
  }
}
</script>
<style>
.sidebar-lists > .b-sidebar {
  width: 400px !important;
}
.avatar-edit-list {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0px auto;
  padding: 0.4em;
  background-color: #ccc;
}
.close-select-campaign-modal {
  display: block;
  color: rgb(155, 155, 155);
  margin: 0 1.3em 0 0;
}
.close-select-campaign-modal:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.sidebar-animation {
  animation: ani 500ms;
}
@keyframes ani {
  0% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
